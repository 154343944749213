import Typography from '@mui/material/Typography';
import * as React from 'react';
import codingforkids from '../assets/codingforkids-cat.png';

export default function LogoWithText() {
  return (
    <Typography
      variant="h6"
      noWrap
      component="a"
      href="/"
      sx={{
        display: 'flex',
        color: 'white',
        letterSpacing: '-0.1rem',
        textDecoration: 'none',
        alignItems: 'center',
        gap: 1,
        fontSize: { xs: '16px', sm: '20px' }
      }}
    >
      <img src={codingforkids} height={32} />
      Coding for Kids
    </Typography>
  );
}
