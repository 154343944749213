import { useQuery, useQueryClient } from 'react-query';
import { AuthService } from '../client';
import { ErrorResponse, useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import { useLocaleContext } from '../context/LocaleContext';
import { tracker } from '../pages/App';

const userTypeMap: {
  [key: string]: string;
} = {
  '0': 'parent',
  '1': 'teacher'
};

export const useCurrentUser = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { getLocaleLink } = useLocaleContext();

  const { data: currentUser, isLoading: isCurrentUserLoading } = useQuery(
    'currentUser',
    AuthService.currentUserApiAuthCurrentGet,
    {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onSuccess: async (fetchedUser) => {
        queryClient.invalidateQueries('courseData');
        queryClient.invalidateQueries(
          `courseData-${fetchedUser?.user_id}-${fetchedUser?.student_id}`
        );
      },
      onError: async (error: AxiosError<ErrorResponse>) => {
        if (error.response && error.response.status === 401) {
          // User is not authenticated
          navigate(getLocaleLink('/login'));
          return;
        }
      }
    }
  );

  if (currentUser) {
    tracker.setUserID(currentUser?.user_id || '');
    tracker.setMetadata('email', currentUser?.email || '');
    const userType = currentUser?.user_details?.user_type;
    if (userType) {
      tracker.setMetadata('user_type', userTypeMap[userType.toString()] || '');
    }
    if (currentUser?.is_student) {
      tracker.setMetadata('student_id', currentUser?.student_id || '');
    }
    if (currentUser.organization_id) {
      tracker.setMetadata('organization_id', currentUser.organization_id);
    }
  }

  return { currentUser, isCurrentUserLoading };
};
